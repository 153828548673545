








































import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import {
  ExternalTestGroupModel,
  ExternalTestModel,
} from "@/ab-tests/models/ExternalTest";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ExternalTestGroup from "@/ab-tests/components/ExternalTestGroup.vue";

@Component({
  components: { ExternalTestGroup },
})
export default class ExternalTestGroups extends Vue {
  @Prop() externalTest!: ExternalTestModel;
  @Prop() valid!: boolean;
  @Prop({ default: false }) readonly!: boolean;

  @Ref("form") readonly form!: VueForm;

  readonly controlGroupRules = [
    ValidUtil.required(this.$lang("externalTest.controlGroupValidation")),
  ];

  get localValid(): boolean {
    return this.valid;
  }

  set localValid(value) {
    this.$emit("update:valid", value);
  }

  get controlGroup(): string | undefined {
    const controlGroupIndex = this.externalTest.testGroups.findIndex(
      ({ control }) => control
    );

    return controlGroupIndex >= 0 ? controlGroupIndex.toString() : undefined;
  }

  set controlGroup(value: string | undefined) {
    if (!value) {
      return;
    }

    this.externalTest.testGroups.forEach((testGroup, index) => {
      if (value === index.toString() && !testGroup.control) {
        testGroup.control = true;
      }

      if (value !== index.toString() && testGroup.control) {
        testGroup.control = false;
      }
    });
  }

  @Watch("externalTest.testGroups", { immediate: true })
  watchResponses(responses: Array<ExternalTestGroupModel>) {
    if (responses.length >= 2) {
      return;
    }

    const responsesToAddCount = 2 - responses.length;

    Array.from(Array(responsesToAddCount)).forEach(() => {
      this.addTestGroup();
    });
  }

  addTestGroup() {
    this.externalTest.testGroups = [
      ...this.externalTest.testGroups,
      new ExternalTestGroupModel(),
    ];
  }

  deleteTestGroup(index: number) {
    this.externalTest.testGroups = this.externalTest.testGroups.filter(
      (group, groupIndex) => groupIndex !== index
    );
  }
}
