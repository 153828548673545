
























































import { Component, Prop, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";

import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";
import ValidUtil from "@/shared/utils/ValidUtil";
import ExternalTestSettings from "@/ab-tests/components/ExternalTestSettings.vue";
import ExternalTestGroups from "@/ab-tests/components/ExternalTestGroups.vue";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    ExternalTestSettings,
    ExternalTestGroups,
  },
})
export default class ExternalNewView extends mixins(UnsavedChangesMixin) {
  @Prop({ default: false }) readonly!: boolean;

  @Ref() settingsForm!: ExternalTestSettings;
  @Ref() groupsForm!: ExternalTestGroups;

  readonly requiredRules = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly steps = [
    this.$lang("commonConfig.settings"),
    this.$lang("commonConfig.testGroups"),
  ];
  readonly externalPageRoute = {
    name: AppSection.EXTERNAL_TESTS,
    params: { id: this.applicationId },
  };
  currentStep = 1;
  externalTest = new ExternalTestModel(this.applicationId);
  isSettingsValid = true;
  isGroupsValid = true;

  get isNextBtnDisabled(): boolean {
    return (
      this.isExternalTestSaving ||
      (this.currentStep === 1 && !this.isSettingsValid) ||
      (this.currentStep === 2 && !this.isGroupsValid)
    );
  }

  get isExternalTestSaving(): boolean {
    return this.$store.state.externalTestStore.isExternalTestSaving;
  }

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.externalTest);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  created() {
    document.title = this.$lang("externalTest.newTitle");
    this.handleWatchingUnsavedChanges();
  }

  goBack() {
    if (this.currentStep > 1) {
      this.currentStep -= 1;

      return;
    }

    this.$router.push(this.externalPageRoute);
  }

  async goNext() {
    if (this.currentStep === 1) {
      if (this.settingsForm.form.validate()) {
        this.groupsForm.form.resetValidation();
        this.currentStep += 1;
      }

      return;
    }

    if (this.currentStep === 2 && !this.groupsForm.form.validate()) {
      return;
    }

    await this.$store.dispatch("saveExternalTest", this.externalTest);
    this.$router.push(this.externalPageRoute);
  }
}
