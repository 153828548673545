

















































































































import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import {
  ExternalTestGroupModel,
  ExternalTestModel,
} from "@/ab-tests/models/ExternalTest";
import {
  AbTestMetricType,
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import {
  Dictionary,
  DictionaryType,
  ExternalTestDictionary,
} from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import DateUtil from "@/shared/utils/DateUtil";

@Component
export default class ExternalTestSettings extends Vue {
  @Prop() externalTest!: ExternalTestModel;
  @Prop() valid!: boolean;
  @Prop({ default: false }) readonly!: boolean;

  @Ref("form") readonly form!: VueForm;

  readonly requiredRules = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly abTestTypes = Object.values(AbTestType).map((value: string) => ({
    text: this.$lang(`abTestConfig.abTestType.${value.toLowerCase()}`),
    value,
  }));
  isMenuVisible = false;

  get localValid(): boolean {
    return this.valid;
  }

  set localValid(value) {
    this.$emit("update:valid", value);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get proposals(): Array<ExternalTestDictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.EXTERNAL_TEST_PROPOSALS
    ].values;
  }

  get externalIds(): Array<string> {
    return this.proposals.map(({ value }) => value);
  }

  get abTestMetricTypes(): Array<Record<string, string>> {
    return Object.values(AbTestMetricType).map((value: string) => ({
      text: this.$lang(`abTestConfig.metricType.${value.toLowerCase()}`),
      value,
    }));
  }

  get dayNumbers(): Array<any> {
    return this.$store.state.dictionaryStore[
      DictionaryType.AB_TEST_DAY_NUMBER
    ].values.map(({ value }: Dictionary) => Number(value));
  }

  get nameErrorMessages(): Array<string> {
    if (
      !this.readonly &&
      !this.$store.state.externalTestStore.isExternalTestNameUnique
    ) {
      return [this.$lang("shared.errors.notUnique")];
    }

    if (this.externalTest.name && this.externalTest.name.length > 50) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }

    return [];
  }

  get idErrorMessages(): Array<string> {
    if (
      !this.readonly &&
      !this.$store.state.externalTestStore.isExternalTestIdUnique
    ) {
      return [this.$lang("shared.errors.notUnique")];
    }

    if (
      this.externalTest.externalId &&
      this.externalTest.externalId.length > 50
    ) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }

    return [];
  }

  get minUsersCount(): number {
    return this.$store.state.abTestConfig.minUsersCount;
  }

  get assignmentTillUsersCountRules(): Array<any> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.integer(this.$lang("validation.integer")),
      ValidUtil.min(
        this.minUsersCount,
        this.$lang("validation.min", this.minUsersCount)
      ),
    ];
  }

  @Watch("externalTest.name")
  watchName = debounce(() => {
    this.$store.dispatch(
      "checkExternalTestNameUniqueness",
      this.externalTest.name
    );
  }, 500);

  @Watch("externalTest.externalId")
  watchExternalTest(externalId: string) {
    const existingExternalTest = this.proposals.find(
      ({ value }) => value === externalId
    );

    if (existingExternalTest) {
      this.externalTest.testGroups = existingExternalTest.testGroups.map(
        (externalId) => new ExternalTestGroupModel(externalId, false)
      );
      this.externalTest.activeSince = DateUtil.formatForDatePicker(
        existingExternalTest.startDate
      );
    } else {
      this.externalTest.testGroups = [];
    }

    this.$store.dispatch("checkExternalTestIdUniqueness", externalId);
  }

  created() {
    if (this.$route.params.externalId) {
      this.externalTest.externalId = this.$route.params.externalId;
    }
  }

  beforeDestroy() {
    this.$store.commit("setExternalTestNameUniqueness", true);
    this.$store.commit("setExternalTestIdUniqueness", true);
  }
}
