





































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {
  ExternalTestModel,
  ExternalTestGroupModel,
} from "@/ab-tests/models/ExternalTest";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class ExternalTestGroup extends Vue {
  @Prop() externalTest!: ExternalTestModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() index!: number;

  readonly requiredRules = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  segmentNameCheckTimeout?: ReturnType<typeof setTimeout>;
  isSegmentNameUnique = true;
  hasSameNameSegment = false;
  isExternalIdUnique = true;

  get areGroupsDeletable(): boolean {
    return this.externalTest.testGroups.length > 2;
  }

  get group(): ExternalTestGroupModel {
    return this.externalTest.testGroups[this.index];
  }

  set group(value: ExternalTestGroupModel) {
    this.externalTest.testGroups[this.index] = ExternalTestGroupModel.of(value);
  }

  get segmentNameErrorMessages(): Array<string> {
    return !this.hasSameNameSegment && this.isSegmentNameUnique
      ? []
      : [this.$lang("shared.errors.notUnique")];
  }

  get externalIdErrorMessages(): Array<string> {
    return this.isExternalIdUnique
      ? []
      : [this.$lang("shared.errors.notUnique")];
  }

  get existingSegmentNames(): Array<string> {
    return this.externalTest.testGroups.reduce(
      (result: Array<string>, group: ExternalTestGroupModel) => [
        ...result,
        ...(group.segmentName ? [group.segmentName] : []),
      ],
      []
    );
  }

  get existingExternalIds(): Array<string> {
    return this.externalTest.testGroups.reduce(
      (result: Array<string>, group: ExternalTestGroupModel) => [
        ...result,
        ...(group.externalId ? [group.externalId] : []),
      ],
      []
    );
  }

  @Watch("existingSegmentNames")
  watchExistingSegmentNames() {
    if (!this.group.segmentName) {
      this.hasSameNameSegment = false;
      return;
    }

    this.hasSameNameSegment =
      this.existingSegmentNames.filter(
        (name) => name === this.group.segmentName
      ).length > 1;
  }

  @Watch("existingExternalIds")
  watchExternalIdErrorMessages() {
    if (!this.group.externalId) {
      this.isExternalIdUnique = true;
      return;
    }

    this.isExternalIdUnique =
      this.existingExternalIds.filter(
        (externalId) => externalId === this.group.externalId
      ).length <= 1;
  }

  @Watch("group.segmentName")
  watchSegmentName() {
    if (this.segmentNameCheckTimeout) {
      clearTimeout(this.segmentNameCheckTimeout);
    }

    this.segmentNameCheckTimeout = setTimeout(async () => {
      this.isSegmentNameUnique = await this.$store.dispatch(
        "checkSegmentNameUniqueness",
        this.group.segmentName
      );
    }, 500);
  }
}
